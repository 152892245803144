/** default export list
 * @type {BaseExport[]}
 */
const baseExports = [
  { key: 'excel', title: 'Excel' },
  { key: 'pdf', title: 'PDF' },
];

export {
  baseExports
};