import request from '@/utils/request'
import { downloadbyurl } from "@/utils/ruoyi";

export function listBiReport(query) {
  return request({
    url: '/bi/bireport/list',
    method: 'get',
    params: query
  })
}

export function getBiReport(id) {
  return request({
    url: '/bi/bireport/get?id=' + id,
    method: 'get'
  })
}

export function addBiReport(data) {
  return request({
    url: '/bi/bireport/add',
    method: 'post',
    data: data
  })
}

export function editBiReport(data) {
  return request({
    url: '/bi/bireport/edit',
    method: 'post',
    data: data
  })
}

export function delBiReport(ids) {
  return request({
    url: '/bi/bireport/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function copyBiReport(id) {
  return request({
    url: '/bi/bireport/copy',
    method: 'post',
    data: {
      id: id
    }
  })
}

export function execBiReport(id, params) {
  return request({
    url: '/bi/bireport/exec',
    method: 'post',
    data: {
      id: id,
      params: params
    }
  })
}

export function importBiReport(data) {
  return request({
    url: '/bi/bireport/import',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function exportBiReport(id, params, type) {
  downloadbyurl('/bi/bireport/export?id=' + id + '&params=' + params + '&type=' + type)
}
