import { stringAt } from '../core/alphabet';
import { getFontSizePxByPt } from '../core/font';
import _cell from '../core/cell';
import { formulam } from '../core/formula';
import { formatm } from '../core/format';
import { loadImage, getImage, loadEmptyImage, getEmptyImage  } from '../core/image';

import {
  Draw, DrawBox, thinLineWidth, npx,
} from '../canvas/draw';
// gobal var
const cellPaddingWidth = 4;
const tableFixedHeaderCleanStyle = { fillStyle: '#f4f5f8' };
const tableGridStyle = {
  fillStyle: '#fff',
  lineWidth: thinLineWidth,
  strokeStyle: '#e6e6e6',
};

function tableFixedHeaderStyle() {
  return {
    textAlign: 'center',
    textBaseline: 'middle',
    font: `500 ${npx(12)}px Source Sans Pro`,
    fillStyle: '#585757',
    lineWidth: thinLineWidth(),
    strokeStyle: '#e6e6e6',
  };
}

function getDrawBox(data, rindex, cindex, xoffset = 0, yoffset = 0) {
  const {
    left, top, width, height,
  } = data.cellRect(rindex, cindex);
  return new DrawBox(left + xoffset, top + yoffset, width, height, cellPaddingWidth);
}
/*
function renderCellBorders(bboxes, translateFunc) {
  const { draw } = this;
  if (bboxes) {
    const rset = new Set();
    // console.log('bboxes:', bboxes);
    bboxes.forEach(({ ri, ci, box }) => {
      if (!rset.has(ri)) {
        rset.add(ri);
        translateFunc(ri);
      }
      draw.strokeBorders(box);
    });
  }
}
*/

export function renderCell(draw, data, rindex, cindex, xoffset = 0, yoffset = 0, isprint = false, redrawcb = undefined, renderbordercb = undefined) {
  const { sortedRowMap, rows, cols } = data;
  if (rows.isHide(rindex) || cols.isHide(cindex)) return;
  let nrindex = rindex;
  if (sortedRowMap.has(rindex)) {
    nrindex = sortedRowMap.get(rindex);
  }

  const cell = data.getCell(nrindex, cindex);
  if (cell === null) return;
  let frozen = false;
  if ('editable' in cell && cell.editable === false) {
    frozen = true;
  }

  let backgroundImage = null
  if (data.backgroundImage && data.backgroundImage.url) {
    backgroundImage = getImage(data.backgroundImage.url)
  }

  const style = data.getCellStyleOrDefault(nrindex, cindex);
  if (backgroundImage) {
      const s = data.getCellStyle(nrindex, cindex);
      if (!s || !s.bgcolor) {
        style.bgcolor = undefined;
      }
  }
  const dbox = getDrawBox(data, rindex, cindex, xoffset, yoffset);
  if (style) {
    dbox.bgcolor = style.bgcolor;
    if (style.border !== undefined) {
      dbox.setBorders(style.border);
      // bboxes.push({ ri: rindex, ci: cindex, box: dbox });
      if (renderbordercb) {
        renderbordercb(dbox);
      } else {
        draw.strokeBorders(dbox);
      }
    }
  }
  
  const cb = () => {
    // render text
    let cellText = '';
    if (!data.settings.evalPaused) {
      cellText = _cell.render(cell.text || '', formulam, (y, x) => (data.getCellTextOrDefault(x, y)));
    } else {
      cellText = cell.text || '';
    }
    if (style.format) {
      // console.log(data.formatm, '>>', cell.format);
      cellText = formatm[style.format].render(cellText);
    }
    const font = Object.assign({}, style.font);
    font.size = getFontSizePxByPt(font.size);
    // console.log('style:', style);
    draw.text(cellText, dbox, {
      align: style.align,
      valign: style.valign,
      font,
      color: style.color,
      strike: style.strike,
      underline: style.underline,
    }, style.textwrap);
    // render image
    if (cell.type == 'image' || cell.type == 'qrcode') {
      if (cell.url) {
        const image = getImage(cell.url);
        if (cell.url && !image) {
          const emptyImage = getEmptyImage()
          if (emptyImage && emptyImage.width && emptyImage.height) {
            draw.image(emptyImage, dbox, cell.imageScale);
          }

          loadImage(cell.url, cell.url, redrawcb)
        } else if (image && image.width && image.height) {
          draw.image(image, dbox, cell.imageScale);
        } else {
          const emptyImage = getEmptyImage()
          if (emptyImage && emptyImage.width && emptyImage.height) {
            draw.image(emptyImage, dbox, cell.imageScale);
          }
        }
      } else {
        const emptyImage = getEmptyImage()
        if (emptyImage && emptyImage.width && emptyImage.height) {
          draw.image(emptyImage, dbox, cell.imageScale);
        }
      }
    }
    // error
    const error = data.validations.getError(rindex, cindex);
    if (error) {
      // console.log('error:', rindex, cindex, error);
      draw.error(dbox);
    }
    if (frozen) {
      draw.frozen(dbox);
    }
  }

  if (dbox.bgcolor) {
    draw.rect(dbox, cb, isprint);
  } else {
    cb();
  }
}

export function renderCellBackground(draw, data, rindex, cindex, xoffset = 0, yoffset = 0, redrawcb = undefined) {
    const { sortedRowMap, rows, cols } = data;
    if (rows.isHide(rindex) || cols.isHide(cindex)) return;
    let nrindex = rindex;
    if (sortedRowMap.has(rindex)) {
      nrindex = sortedRowMap.get(rindex);
    }
  
    const cell = data.getCell(nrindex, cindex);
    if (cell === null) return;
    let frozen = false;
    if ('editable' in cell && cell.editable === false) {
      frozen = true;
    }
  
    const style = data.getCellStyleOrDefault(nrindex, cindex);
    const dbox = getDrawBox(data, rindex, cindex, xoffset, yoffset);
    if (style) {
      dbox.bgcolor = style.bgcolor;
    }
    
    if (dbox.bgcolor) {
      draw.rect(dbox, () => {});
    }
  }

function renderAutofilter(viewRange) {
  const { data, draw } = this;
  if (viewRange) {
    const { autoFilter } = data;
    if (!autoFilter.active()) return;
    const afRange = autoFilter.hrange();
    if (viewRange.intersects(afRange)) {
      afRange.each((ri, ci) => {
        const dbox = getDrawBox(data, ri, ci);
        draw.dropdown(dbox);
      });
    }
  }
}

function renderContent(viewRange, fw, fh, tx, ty, redrawcb = undefined) {
  const { draw, data } = this;
  const { exceptRowSet } = data;
  // const exceptRows = Array.from(exceptRowSet);
  const filteredTranslateFunc = (ri) => {
    const ret = exceptRowSet.has(ri);
    if (ret) {
      const height = data.rows.getHeight(ri);
      draw.translate(0, -height);
    }
    return !ret;
  };

  const exceptRowTotalHeight = data.exceptRowTotalHeight(viewRange.sri, viewRange.eri);

  // render mergeCell background
  const rsetbg = new Set();
  draw.save();
  draw.translate(fw, fh)
    .translate(tx, ty);
  draw.translate(0, -exceptRowTotalHeight);
  data.eachMergesInView(viewRange, ({ sri, sci, eri, eci }) => {
    if (!exceptRowSet.has(sri)) {
      renderCellBackground(draw, data, sri, sci, 0, 0, redrawcb);
    } else if (!rsetbg.has(sri)) {
      rsetbg.add(sri);
      const height = data.rows.sumHeight(sri, eri + 1);
      draw.translate(0, -height);
    }
  });
  draw.restore();

  // render background image
  draw.save();
  draw.translate(fw, fh)
    .translate(tx, ty);
  if (data.backgroundImage && data.backgroundImage.url) {
    const image = getImage(data.backgroundImage.url)
    if (!image) {
        loadImage(data.backgroundImage.url, data.backgroundImage.url, () => {
            this.render()
        })
    }
    if (image && image.width && image.height) {
      const dbox = getDrawBox(data, 0, 0, 0, 0);
      dbox.width = data.backgroundImage.width || image.width;
      dbox.height = data.backgroundImage.height || image.height;
      draw.save()
      draw.image(image, dbox);
      draw.restore()
    }
  }

  const borderdboxes = [];
  const renderbordercb = (dbox) => {
    //draw.strokeBorders(dbox);
    borderdboxes.push(dbox);
  }

  // render cell
  draw.save();
  draw.translate(0, -exceptRowTotalHeight);
  viewRange.each((ri, ci) => {
    const cell = data.getCell(ri, ci)
    if (!cell || (cell.merge && cell.merge.length > 0)) {
      return
    }
    renderCell(draw, data, ri, ci, 0, 0, false, redrawcb, renderbordercb);
  }, ri => filteredTranslateFunc(ri));
  draw.restore();

  // render mergeCell
  const rset = new Set();
  draw.save();
  draw.translate(0, -exceptRowTotalHeight);
  data.eachMergesInView(viewRange, ({ sri, sci, eri }) => {
    if (!exceptRowSet.has(sri)) {
      renderCell(draw, data, sri, sci, 0, 0, false, redrawcb, renderbordercb);
    } else if (!rset.has(sri)) {
      rset.add(sri);
      const height = data.rows.sumHeight(sri, eri + 1);
      draw.translate(0, -height);
    }
  });

  for (let i = 0; i < borderdboxes.length; i++) {
    draw.strokeBorders(borderdboxes[i]);
  }

  draw.restore();

  // render autofilter
  renderAutofilter.call(this, viewRange);

  draw.restore();
}

function renderSelectedHeaderCell(x, y, w, h) {
  const { draw } = this;
  draw.save();
  draw.attr({ fillStyle: 'rgba(75, 137, 255, 0.08)' })
    .fillRect(x, y, w, h);
  draw.restore();
}

// viewRange
// type: all | left | top
// w: the fixed width of header
// h: the fixed height of header
// tx: moving distance on x-axis
// ty: moving distance on y-axis
function renderFixedHeaders(type, viewRange, w, h, tx, ty) {
  const { draw, data } = this;
  if (!data.settings.showFixedHeader) {
      return
  }
  const sumHeight = viewRange.h; // rows.sumHeight(viewRange.sri, viewRange.eri + 1);
  const sumWidth = viewRange.w; // cols.sumWidth(viewRange.sci, viewRange.eci + 1);
  const nty = ty + h;
  const ntx = tx + w;

  draw.save();
  // draw rect background
  draw.attr(tableFixedHeaderCleanStyle);
  if (type === 'all' || type === 'left') draw.fillRect(0, nty, w, sumHeight);
  if (type === 'all' || type === 'top') draw.fillRect(ntx, 0, sumWidth, h);

  const {
    sri, sci, eri, eci,
  } = data.selector.range;
  // console.log(data.selectIndexes);
  // draw text
  // text font, align...
  draw.attr(tableFixedHeaderStyle());
  // y-header-text
  if (type === 'all' || type === 'left') {
    data.rowEach(viewRange.sri, viewRange.eri, (i, y1, rowHeight) => {
      const y = nty + y1;
      const ii = i;
      draw.line([0, y], [w, y]);
      if (sri <= ii && ii < eri + 1) {
        renderSelectedHeaderCell.call(this, 0, y, w, rowHeight);
      }
      draw.fillText(ii + 1, w / 2, y + (rowHeight / 2));
      if (i > 0 && data.rows.isHide(i - 1)) {
        draw.save();
        draw.attr({ strokeStyle: '#c6c6c6' });
        draw.line([5, y + 5], [w - 5, y + 5]);
        draw.restore();
      }
    });
    draw.line([0, sumHeight + nty], [w, sumHeight + nty]);
    draw.line([w, nty], [w, sumHeight + nty]);
  }
  // x-header-text
  if (type === 'all' || type === 'top') {
    data.colEach(viewRange.sci, viewRange.eci, (i, x1, colWidth) => {
      const x = ntx + x1;
      const ii = i;
      draw.line([x, 0], [x, h]);
      if (sci <= ii && ii < eci + 1) {
        renderSelectedHeaderCell.call(this, x, 0, colWidth, h);
      }
      draw.fillText(stringAt(ii), x + (colWidth / 2), h / 2);
      if (i > 0 && data.cols.isHide(i - 1)) {
        draw.save();
        draw.attr({ strokeStyle: '#c6c6c6' });
        draw.line([x + 5, 5], [x + 5, h - 5]);
        draw.restore();
      }
    });
    draw.line([sumWidth + ntx, 0], [sumWidth + ntx, h]);
    draw.line([0, h], [sumWidth + ntx, h]);
  }
  draw.restore();
}

function renderFixedLeftTopCell(fw, fh) {
  const { draw } = this;
  draw.save();
  // left-top-cell
  draw.attr({ fillStyle: '#f4f5f8' })
    .fillRect(0, 0, fw, fh);
  draw.restore();
}

function renderContentGrid({
  sri, sci, eri, eci, w, h,
}, fw, fh, tx, ty) {
  const { draw, data } = this;
  const { settings } = data;

  draw.save();
  draw.attr(tableGridStyle)
    .translate(fw + tx, fh + ty);
  // const sumWidth = cols.sumWidth(sci, eci + 1);
  // const sumHeight = rows.sumHeight(sri, eri + 1);
  // console.log('sumWidth:', sumWidth);
  // draw.clearRect(0, 0, w, h);
  if (!settings.showGrid) {
    draw.restore();
    return;
  }
  // console.log('rowStart:', rowStart, ', rowLen:', rowLen);
  data.rowEach(sri, eri, (i, y, ch) => {
    // console.log('y:', y);
    if (i !== sri) draw.line([0, y], [w, y]);
    if (i === eri) draw.line([0, y + ch], [w, y + ch]);
  });
  data.colEach(sci, eci, (i, x, cw) => {
    if (i !== sci) draw.line([x, 0], [x, h]);
    if (i === eci) draw.line([x + cw, 0], [x + cw, h]);
  });
  draw.restore();
}

const PAGER_SIZES = [
  ['A3', 11.69, 16.54],
  ['A4', 8.27, 11.69],
  ['A5', 5.83, 8.27],
  ['B4', 9.84, 13.90],
  ['B5', 6.93, 9.84],
];

function getPageSize(psize) {
    for (let i = 0; i < PAGER_SIZES.length; i++) {
        const s = PAGER_SIZES[i]
        if (s[0] == psize) {
            return s
        }
    }
    return null
}

function inches2px(inc) {
  return parseInt(96 * inc, 10);
}

function renderPrintGrid({
  sri, sci, eri, eci, w, h,
}, fw, fh, tx, ty, sx, sy) {
  const { draw, data } = this;
  const { settings } = data
  if (!settings.showPrintGrid) {
      return
  }
  const size = getPageSize(settings.print ? settings.print.size : 'A4')
  const landscape = settings.print ? settings.print.orientation == 'landscape' : false
  const paddingLeft = settings.print ? settings.print.paddingLeft : 1.91
  const paddingRight = settings.print ? settings.print.paddingRight : 1.91
  const paddingTop = settings.print ? settings.print.paddingTop : 2.54
  const paddingBottom = settings.print ? settings.print.paddingBottom : 2.54

  const scaleX = 1//127 / 140.0
  const scaleY = 1//landscape ? 0.964 : 1.038
  const pw = (inches2px(landscape ? size[2] : size[1]) - inches2px(paddingLeft / 2.54) - inches2px(paddingRight / 2.54))  * scaleX
  const ph = (inches2px(landscape ? size[1] : size[2]) - inches2px(paddingTop / 2.54) - inches2px(paddingBottom / 2.54)) * scaleY

  draw.save();
  draw.translate(fw + tx, fh + ty);
  draw.border("dashed", "#66f")
  /*for (let i = 1; i < 5; i++) {
    draw.line([pw*i, 0], [pw*i, h])
  }
  for (let i = 1; i < 5; i++) {
    draw.line([0, ph*i-sy], [w, ph*i-sy])
  }*/
  let lastX = 0
  let lastPageX = 0
  let x = 0;
  for (let i = 0; i <= eci; i += 1) {
    const colWidth = data.cols.getWidth(i);
    if (colWidth > 0) {
        if ((x - lastPageX) <= pw) {
            lastX = x
            x += colWidth
            continue
        }
        if (i !== 0 && (lastX - sx) > 0) draw.line([lastX - sx, 0], [lastX - sx, h]);
        //if (i === eci) draw.line([lastX - sx + colWidth, 0], [lastX - sx + colWidth, h]);
        lastPageX = lastX
        lastX = x
        x += colWidth
    }
  }

  let lastY = 0
  let lastPageY = 0
  let y = 0;
  const frset = data.exceptRowSet;
  const frary = [...frset];
  let offset = 0;
  for (let i = 0; i < frary.length; i += 1) {
    if (frary[i] < min) {
      offset += 1;
    }
  }
  for (let i = 0 + offset; i <= eri + offset; i += 1) {
    if (frset.has(i)) {
      offset += 1;
    } else {
      const rowHeight = data.rows.getHeight(i);
      if (rowHeight > 0) {
          if ((y - lastPageY) <= ph) {
              lastY = y
              y += rowHeight
              continue
          }
          if (i !== 0 && (lastY - sy) > 0) draw.line([0, lastY - sy], [w, lastY - sy]);
          lastPageY = lastY
          lastY = y
          y += rowHeight
      }
    }
  }

  draw.restore();
}

function renderFreezeHighlightLine(fw, fh, ftw, fth) {
  const { draw, data } = this;
  const twidth = data.viewWidth() - fw;
  const theight = data.viewHeight() - fh;
  draw.save()
    .translate(fw, fh)
    .attr({ strokeStyle: 'rgba(75, 137, 255, .6)' });
  draw.line([0, fth], [twidth, fth]);
  draw.line([ftw, 0], [ftw, theight]);
  draw.restore();
}

/** end */
class Table {
  constructor(el, data) {
    this.el = el;
    this.draw = new Draw(el, data.viewWidth(), data.viewHeight());
    this.data = data;
    if (data.settings.emptyImage) {
      loadEmptyImage(data.settings.emptyImage, () => {
        this.render()
      })
    }
  }

  resetData(data) {
    this.data = data;
    this.render();
  }

  render() {
    // resize canvas
    const { data } = this;
    const { rows, cols, settings } = data;
    // fixed width of header
    const fw = settings.showFixedHeader ? cols.indexWidth : 0;
    // fixed height of header
    const fh = settings.showFixedHeader ? rows.height : 0;

    this.draw.resize(data.viewWidth(), data.viewHeight());
    this.clear();

    const viewRange = data.viewRange();
    // renderAll.call(this, viewRange, data.scroll);
    const tx = data.freezeTotalWidth();
    const ty = data.freezeTotalHeight();
    const { x, y } = data.scroll;
    // 1
    renderContentGrid.call(this, viewRange, fw, fh, tx, ty);
    renderContent.call(this, viewRange, fw, fh, -x, -y, () => this.render());
    renderPrintGrid.call(this, viewRange, fw, fh, tx, ty, x, y);
    renderFixedHeaders.call(this, 'all', viewRange, fw, fh, tx, ty);
    renderFixedLeftTopCell.call(this, fw, fh);
    const [fri, fci] = data.freeze;
    if (fri > 0 || fci > 0) {
      // 2
      if (fri > 0) {
        const vr = viewRange.clone();
        vr.sri = 0;
        vr.eri = fri - 1;
        vr.h = ty;
        renderContentGrid.call(this, vr, fw, fh, tx, 0);
        renderContent.call(this, vr, fw, fh, -x, 0, () => this.render());
        renderPrintGrid.call(this, vr, fw, fh, tx, 0);
        renderFixedHeaders.call(this, 'top', vr, fw, fh, tx, 0);
      }
      // 3
      if (fci > 0) {
        const vr = viewRange.clone();
        vr.sci = 0;
        vr.eci = fci - 1;
        vr.w = tx;
        renderContentGrid.call(this, vr, fw, fh, 0, ty);
        renderFixedHeaders.call(this, 'left', vr, fw, fh, 0, ty);
        renderContent.call(this, vr, fw, fh, 0, -y, () => this.render());
        renderPrintGrid.call(this, vr, fw, fh, 0, ty);
      }
      // 4
      const freezeViewRange = data.freezeViewRange();
      renderContentGrid.call(this, freezeViewRange, fw, fh, 0, 0);
      renderFixedHeaders.call(this, 'all', freezeViewRange, fw, fh, 0, 0);
      renderContent.call(this, freezeViewRange, fw, fh, 0, 0, () => this.render());
      renderPrintGrid.call(this, freezeViewRange, fw, fh, 0, 0);
      // 5
      renderFreezeHighlightLine.call(this, fw, fh, tx, ty);
    }
  }

  clear() {
    this.draw.clear();
  }
}

export default Table;
