import Dropdown from './dropdown';
import Icon from './icon';
import { h } from './element';
import { baseExports } from '../core/export';
import { cssPrefix } from '../config';

export default class DropdownExport extends Dropdown {
  constructor() {
    const nformulas = baseExports.map(it => h('div', `${cssPrefix}-item`)
      .on('click', () => {
        this.hide();
        this.change(it);
      })
      .child(it.title));
    super(new Icon('export'), '180px', true, 'bottom-left', ...nformulas);
  }
}
