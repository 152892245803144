const imageCache = {};
const emptyImageKey = "emptyimage";

export function loadImage(key, src, cb) {
  let image = imageCache[key];
  if (!image) {
    image = new Image();
    image.src = src;
    image.onload = () => {
      if (cb) {
        cb(image);
      }
    }
    imageCache[key] = image;
  }
  return image;
}

export function getImage(key) {
  let image = imageCache[key];
  return image;
}

export function loadEmptyImage(src, cb) {
  return loadImage(emptyImageKey, src, cb)
}

export function getEmptyImage() {
  return getImage(emptyImageKey)
}