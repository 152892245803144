/* global window document */
import { h } from './element';
import { cssPrefix } from '../config';
import Button from './button';
import { Draw } from '../canvas/draw';
import { renderCell } from './table';
import { t } from '../locale/locale';
import { stringAt } from '../core/alphabet';

// resolution: 72 => 595 x 842
// 150 => 1240 x 1754
// 200 => 1654 x 2339
// 300 => 2479 x 3508
// 96 * cm / 2.54 , 96 * cm / 2.54

const PAGER_SIZES = [
  ['A3', 11.69, 16.54],
  ['A4', 8.27, 11.69],
  ['A5', 5.83, 8.27],
  ['B4', 9.84, 13.90],
  ['B5', 6.93, 9.84],
];

const PAGER_ORIENTATIONS = ['landscape', 'portrait'];

const PAGER_DPIS = [96, 200, 300];

function inches2px(inc) {
  return parseInt(96 * inc, 10);
}

function getPageSize(psize) {
    for (let i = 0; i < PAGER_SIZES.length; i++) {
        const s = PAGER_SIZES[i]
        if (s[0] == psize) {
            return s
        }
    }
    return null
}

function btnClick(type) {
  if (type === 'cancel') {
    this.el.hide();
  } else {
    this.toPrint();
  }
}

function pagerSizeChange(evt) {
  const { paper } = this;
  const { value } = evt.target;
  const ps = PAGER_SIZES[value];
  paper.w = inches2px(ps[1]);
  paper.h = inches2px(ps[2]);
  // console.log('paper:', ps, paper);
  this.preview();
}

function pagerOrientationChange(evt) {
  const { paper } = this;
  const { value } = evt.target;
  const v = PAGER_ORIENTATIONS[value];
  paper.orientation = v;
  this.preview();
}

function pagerDpiChange(evt) {
    const { paper } = this;
    const { value } = evt.target;
    const v = PAGER_DPIS[value];
    paper.dpi = v;
    this.preview();
  }

export default class Print {
  constructor(data) {
    this.paper = {
      size: PAGER_SIZES[0][0],
      w: inches2px(PAGER_SIZES[0][1]),
      h: inches2px(PAGER_SIZES[0][2]),
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 50,
      paddingBottom: 50,
      orientation: PAGER_ORIENTATIONS[0],
      dpi: 96,
      get width() {
        return this.orientation === 'landscape' ? this.h : this.w;
      },
      get height() {
        return this.orientation === 'landscape' ? this.w : this.h;
      },
    };
    this.data = data;
    this.resetDom()
  }

  resetDom() {
    const { paper } = this;
    this.el = h('div', `${cssPrefix}-print`)
    .children(
      h('div', `${cssPrefix}-print-bar`)
        .children(
          h('div', '-title').child('Print settings'),
          h('div', '-right').children(
            h('div', `${cssPrefix}-buttons`).children(
              new Button('cancel').on('click', btnClick.bind(this, 'cancel')),
              new Button('next', 'primary').on('click', btnClick.bind(this, 'next')),
            ),
          ),
        ),
      h('div', `${cssPrefix}-print-content`)
        .children(
          this.contentEl = h('div', '-content'),
          h('div', '-sider').child(
            h('form', '').children(
              h('fieldset', '').children(
                h('label', '').child(`${t('print.size')}`),
                h('select', '').children(
                  ...PAGER_SIZES.map((it, index) => {
                    const optionEl = h('option', '').attr('value', index).child(`${it[0]} ( ${it[1]}''x${it[2]}'' )`);
                    if (it[0] == paper.size) {
                        optionEl.attr('selected', 'selected')
                    }
                    return optionEl;
                  }),
                ).on('change', pagerSizeChange.bind(this)),
              ),
              h('fieldset', '').children(
                h('label', '').child(`${t('print.orientation')}`),
                h('select', '').children(
                  ...PAGER_ORIENTATIONS.map((it, index) => {
                    const optionEl = h('option', '').attr('value', index).child(`${t('print.orientations')[index]}`);
                    if (it == paper.orientation) {
                        optionEl.attr('selected', 'selected')
                    }
                    return optionEl;
                  }),
                ).on('change', pagerOrientationChange.bind(this)),
              ),
              h('fieldset', '').children(
                h('label', '').child(`${t('print.dpi')}`),
                h('select', '').children(
                  ...PAGER_DPIS.map((it, index) => {
                    const optionEl = h('option', '').attr('value', index).child(`${it}dpi`);
                    if (it == paper.dpi) {
                        optionEl.attr('selected', 'selected')
                    }
                    return optionEl;
                  }),
                ).on('change', pagerDpiChange.bind(this)),
              ),
            ),
          ),
        ),
    ).hide();
  }

  resetData(data) {
    this.data = data;
    const { paper } = this;
    const { settings } = this.data;
    if (settings.print) {
        if (settings.print.size) {
            const ps = getPageSize(settings.print.size)
            paper.size = ps[0];
            paper.w = inches2px(ps[1]);
            paper.h = inches2px(ps[2]);
        }
        if (settings.print.paddingLeft !== undefined) {
            paper.paddingLeft = inches2px(settings.print.paddingLeft / 2.54)
        }
        if (settings.print.paddingRight !== undefined) {
            paper.paddingRight = inches2px(settings.print.paddingRight / 2.54)
        }
        if (settings.print.paddingTop !== undefined) {
            paper.paddingTop = inches2px(settings.print.paddingTop / 2.54)
        }
        if (settings.print.paddingBottom !== undefined) {
            paper.paddingBottom = inches2px(settings.print.paddingBottom / 2.54)
        }
        if (settings.print.orientation) {
            paper.orientation = settings.print.orientation;
        }
    }
    this.resetDom();
  }

  preview() {
    const { data, paper } = this;
    const { width, height, paddingLeft, paddingRight, paddingTop, paddingBottom, dpi } = paper;
    // 在wps中excel打印的边距是正确的，但是内容缩小了
    const scaleX = 1//127 / 140.0
    const scaleY = 1//paper.orientation == 'landscape' ? 0.964 : 1.038
    const iwidth = (width - paddingLeft - paddingRight) * scaleX;
    const iheight = (height - paddingTop - paddingBottom) * scaleY;
    const cr = data.contentRange();
    const scale = dpi / 96
    const left = paddingLeft;
    const top = paddingTop;

    const hPages = {}
    const colPages = {}
    let lastHPage = 1
    let lastCi = 0;
    let lastSci = 0;
    let lastX = 0
    let lastPageX = 0
    let colWidth = 0
    let x = 0;
    for (let i = 0; i <= cr.eci; i += 1) {
        colWidth = data.cols.getWidth(i);
        if (colWidth > 0) {
            if ((x + colWidth - lastPageX) <= iwidth) {
                colPages[i] = lastHPage
                lastCi = i
                x += colWidth
                lastX = x
                continue
            }
            if (i !== 0 && lastX > 0) {
                hPages[lastHPage] = {
                    hPage: lastHPage,
                    hPageX: lastPageX,
                    hPageWidth: lastX - lastPageX,
                    sci: lastSci,
                    eci: lastCi
                }
                //console.log(lastHPage, hPages[lastHPage])
                lastHPage++
            }
            colPages[i] = lastHPage
            lastPageX = lastX
            lastCi = i
            lastSci = lastCi
            x += colWidth
            lastX = x
        }
    }
    hPages[lastHPage] = {
        hPage: lastHPage,
        hPageX: lastPageX,
        hPageWidth: x - lastPageX,
        sci: lastSci,
        eci: lastCi
    }
    //console.log(lastHPage, hPages[lastHPage])

    const vPages = {}
    const rowPages = {}
    let lastVPage = 1
    let lastRi = 0
    let lastSri = 0
    let lastY = 0
    let lastPageY = 0
    let y = 0;
    const frset = data.exceptRowSet;
    const frary = [...frset];
    let offset = 0;
    for (let i = 0; i < frary.length; i += 1) {
        if (frary[i] < min) {
            offset += 1;
        }
    }
    for (let i = 0 + offset; i <= cr.eri + offset; i += 1) {
        if (frset.has(i)) {
            offset += 1;
        } else {
            const rowHeight = data.rows.getHeight(i);
            if (rowHeight > 0) {
                if ((y + rowHeight - lastPageY) <= iheight) {
                    rowPages[i] = lastVPage
                    lastRi = i
                    y += rowHeight
                    lastY = y
                    continue
                }
                if (i !== 0 && lastY > 0) {
                    vPages[lastVPage] = {
                        vPage: lastVPage,
                        vPageY: lastPageY,
                        vPageHeight: lastY - lastPageY,
                        sri: lastSri,
                        eri: lastRi
                    }
                    lastVPage++
                    //console.log(lastRi)
                }
                rowPages[i] = lastVPage
                lastPageY = lastY
                lastRi = i
                lastSri = lastRi
                y += rowHeight
                lastY = y
            }
        }
    }
    vPages[lastVPage] = {
        vPage: lastVPage,
        vPageY: lastPageY,
        vPageHeight: y - lastPageY,
        sri: lastSri,
        eri: lastRi
    }
    //console.log(lastVPage, vPages[lastVPage])

    let maxVPage = 1
    for (let ri = 0; ri <= cr.eri; ri += 1) {
        for (let ci = 0; ci <= cr.eci; ci += 1) {
            const cell = data.getCell(ri, ci)
            if (cell && (cell.text || cell.url || cell.style !== undefined)) {
                let vPage = vPages[rowPages[ri]]
                if (vPage && vPage.vPage > maxVPage) {
                    maxVPage = vPage.vPage
                }
                if (cell.merge && cell.merge.length > 0) {
                    vPage = vPages[rowPages[ri + cell.merge[0]]]
                    if (vPage && vPage.vPage > maxVPage) {
                        maxVPage = vPage.vPage
                    }
                }
            }
        }
    }

    let maxNPage = 1
    const pages = {}
    for (let ri = 0; ri <= cr.eri; ri += 1) {
        for (let ci = 0; ci <= cr.eci; ci += 1) {
            const cell = data.getCell(ri, ci)
            if (cell && (cell.text || cell.url || cell.style !== undefined || (cell.merge && cell.merge.length > 0))) {
                const hPage = hPages[colPages[ci]]
                const vPage = vPages[rowPages[ri]]
                const nPage = (hPage.hPage-1)*maxVPage + vPage.vPage
                if (nPage > maxNPage) {
                    maxNPage = nPage
                }
                let page = pages[nPage]
                if (!page) {
                    page = {
                        cells: [],
                        hPage: hPage,
                        vPage: vPage
                    }
                    pages[nPage] = page
                }

                page.cells.push({
                    ri: ri,
                    ci: ci,
                    hPage: hPage,
                    vPage: vPage,
                    nPage: nPage
                })

                if (cell.merge && cell.merge.length > 0) {
                    for (let mri = ri; mri <= ri + cell.merge[0]; mri++) {
                        for (let mci = ci; mci <= ci + cell.merge[1]; mci++) {
                            if (mri == ri && mci == ci) {
                                continue
                            }
                            const hPage = hPages[colPages[mci]]
                            const vPage = vPages[rowPages[mri]]
                            if (!hPage || !vPage) {
                                continue
                            }
                            const nPage = (hPage.hPage-1)*maxVPage + vPage.vPage
                            if (nPage > maxNPage) {
                                maxNPage = nPage
                            }
                            let page = pages[nPage]
                            if (!page) {
                                page = {
                                    cells: [],
                                    hPage: hPage,
                                    vPage: vPage
                                }
                                pages[nPage] = page
                            }
                        }
                    }
                }
            }
        }
    }

    const redrawcb = () => {
        this.preview()
    }

    this.contentEl.html('');
    this.canvases = [];
    const mViewRange = {
      sri: 0,
      sci: 0,
      eri: 0,
      eci: 0,
    };
    for (let i = 0; i < maxNPage; i += 1) {
      const page = pages[i+1]
      if (!page) {
          continue
      }

      const hPageWidth = page.hPage.hPageWidth
      const vPageHeight = page.vPage.vPageHeight

      const borderdboxes = [];
      const renderbordercb = (dbox) => {
        //draw.strokeBorders(dbox);
        borderdboxes.push(dbox);
      }

      const wrap = h('div', `${cssPrefix}-canvas-card`).attr('style', `width: ${width}px; height: ${height}px`);
      const canvas = h('canvas', `${cssPrefix}-canvas`);
      this.canvases.push(canvas.el);
      const draw = new Draw(canvas.el, Math.floor(width*scale), Math.floor(height*scale));
      draw.el.style.width = `${width}px`
      draw.el.style.height = `${height}px`
      // cell-content
      draw.save();
      if (scale != 1) draw.scale(scale, scale);
      draw.translate(left, top);
      draw.ctx.rect(0, 0, hPageWidth, vPageHeight)
      draw.ctx.clip()
      mViewRange.sri = page.vPage.sri
      mViewRange.sci = page.hPage.sci
      mViewRange.eri = page.vPage.eri
      mViewRange.eci = page.hPage.eci
      for (let j = 0; j < page.cells.length; j++) {
          const c = page.cells[j]
          const cell = data.getCell(c.ri, c.ci)
          if (cell && (!cell.merge || cell.merge.length == 0)) {
            renderCell(draw, data, c.ri, c.ci, -c.hPage.hPageX, -c.vPage.vPageY, true, redrawcb, renderbordercb);
          }
      }
      draw.restore();
      // merge-cell
      draw.save();
      if (scale != 1) draw.scale(scale, scale);
      draw.translate(left, top);
      draw.ctx.rect(0, 0, hPageWidth, vPageHeight)
      draw.ctx.clip()
      if (mViewRange.sri != -1 && mViewRange.sci != -1 && mViewRange.eri != -1 && mViewRange.eci != -1) {
        data.eachMergesInView(mViewRange, ({ sri, sci }) => {
            const hPage = hPages[colPages[sci]]
            const vPage = vPages[rowPages[sri]]
            let offsetX = -hPage.hPageX
            let offsetY = -vPage.vPageY
            if (sci < mViewRange.sci) {
                const thisHPage = hPages[colPages[mViewRange.sci]]
                offsetX -= (thisHPage.hPageX - hPage.hPageX)
            }
            if (sri < mViewRange.sri) {
                const thisVPage = vPages[rowPages[mViewRange.sri]]
                offsetY -= (thisVPage.vPageY - vPage.vPageY)
            }
            renderCell(draw, data, sri, sci, offsetX, offsetY, true, redrawcb, renderbordercb);
        });
      }

      for (let i = 0; i < borderdboxes.length; i++) {
        draw.strokeBorders(borderdboxes[i]);
      }

      draw.restore();

      this.contentEl.child(h('div', `${cssPrefix}-canvas-card-wraper`).attr('style', `min-width: ${width+40}px;`).child(wrap.child(canvas)));
    }
    this.el.show();
  }

  toPrint() {
    this.el.hide();
    const { paper } = this;
    const iframe = h('iframe', '').hide();
    const { el } = iframe;
    window.document.body.appendChild(el);
    const { contentWindow } = el;
    const idoc = contentWindow.document;
    const style = document.createElement('style');
    style.innerHTML = `
      @page { size: ${paper.width}px ${paper.height}px; };
      canvas {
        page-break-before: auto;        
        page-break-after: always;
        image-rendering: pixelated;
      };
    `;
    idoc.head.appendChild(style);
    this.canvases.forEach((it) => {
      const cn = it.cloneNode(false);
      const ctx = cn.getContext('2d');
      // ctx.imageSmoothingEnabled = true;
      ctx.drawImage(it, 0, 0);
      idoc.body.appendChild(cn);
    });
    contentWindow.print();
  }
}
